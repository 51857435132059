.wrapper{
    overflow-x: hidden;
    overflow-y: auto ;
    background-color: white ;
    background-image: url('https://images.unsplash.com/photo-1551001626-86e913f8baf7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}

/* navbar */
.navbox {
    width: 100%;
    height:auto;
    border-bottom: 1px solid black;
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    padding: 7px 25px;
}

.navbox .leftside {
    width: 250px;
    height: 250px;
    

}

.navbox .leftside img {
    width: 100%;
    height: 100%;
}

.navbox .rightside {
    display:flex;
    flex-direction: row;
}


.navbox .rightside .navlinks {
    font-size:12px;
    color: #000;
    font-weight: 600;
    margin: auto 10px;
}
